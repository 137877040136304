<template lang="pug">
  app-dialog(:title="title" :value="showing" width="400" @click:outside="toggle" @input="cancel")
    template(#dialog-action)
      slot(name="activator" :toggle="toggle")
    template(#dialog-body)
      .ds-create__body
        p(v-if="removable").text-error Are you sure you want to remove driving school?
        app-input(
          v-model="formData.name"
          placeholder="Name"
          :error="formErrors.hasError('name')"
          :error-messages="formErrors.fields.name"
          :readonly="removable"
        )
        app-input(
          v-model="formData.number"
          placeholder="Number"
          :error="formErrors.hasError('number')"
          :error-messages="formErrors.fields.number"
          :readonly="removable"
        )
        app-input(
          v-model="formData.slug"
          placeholder="Slug"
          :error="formErrors.hasError('slug')"
          :error-messages="formErrors.fields.slug"
          :readonly="removable"
        )
    template(#dialog-footer)
      v-spacer
      app-button(color="#f4f7f9" hide-shadow @click.native="cancel").mr-3
        span.config-cancel Cancel
      app-button(v-if="!editable && !removable" color="primary" @click.native="submit")
        span Submit
      app-button(v-if="editable" color="primary" @click.native="update")
        span Update
      app-button(v-if="removable" color="error" @click.native="remove")
        span Remove
</template>

<script>
import DrivingSchoolValidator from "../core/models/drivingSchoolValidator"
import { DrivingSchoolService as Svc } from "../core/drivingSchool-service"
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import FormErrors from "@/util/form-errors"
import appButton from '@/components/global/actions/BaseButton.vue'

export default {
  name: 'DrivingSchoolCRUD',

  mixins: [errorsMixin, showingMixin],

  props: {
    item: Object,
    editable: Boolean,
    removable: Boolean
  },

  computed: {
    title() {
      if (this.editable) return 'Edit driving school'
      if (this.removable) return 'Remove driving school'
      else return 'Add driving school'
    }
  },

  data: () => ({
    formData: {
      name: '',
      number: null,
      slug: ''
    },
    formErrors: new FormErrors(),
    loading: false
  }),

  methods: {
    submit() {
      if (!new DrivingSchoolValidator(this.formData, this.formErrors).validate()) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      if (!this.editable && !this.removable) this.create()
      if (this.editable) this.update()
      if (this.removable) this.remove()
    },

    cancel() {
      this.resetFormData()
      this.updateShowing(false)
    },

    prefill() {
      if (this.item) this.formData = { ...this.formData, ...this.item}
    },

    async create() {
      try {
        this.loading = true
        let res = await new Svc().post(this.formData)
        this.$emit('needToUpdate', res)
        this.$notify({text: 'Driving school created', type: 'success'})
        this.toggle()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async update() {
      try {
        this.loading = true
        let res = await new Svc().put(this.formData.id, this.formData)
        this.$emit('needToUpdate', res)
        this.$notify({text: 'Driving school updated', type: 'success'})
        this.toggle()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async remove() {
      try {
        this.loading = true
        let res = await new Svc().remove(this.formData.id)
        this.$emit('needToUpdate', res)
        this.$notify({text: 'Driving school removed', type: 'success'})
        this.toggle()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    resetFormData() {
      this.formData = {
        name: '',
        number: null,
        slug: ''
      }
    }
  },

  watch: {
    showing(isShowing) {
      if (isShowing) {
        if (this.editable || this.removable) this.prefill()
        this.$emit('crudOpen')
      }
      else this.resetFormData()
    }
  },

  components: {
    appButton,
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appInput: () => import('@/components/global/actions/BaseInput.vue')
  }
}
</script>

<style lang="scss">
.config-cancel {
  color: $label-color;
}

.ds-create__body {
  padding: 10px;
}
</style>
